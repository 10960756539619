import GolfService from '@/views/modules/ultils/GolfService/GolfService.vue'
import SelectCheckbox from '@/views/modules/ultils/SelectCheckbox/SelectCheckbox.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max,
} from '@validations'

import Cleave from 'vue-cleave-component'
import {
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12,  S, R, P
} from '@/api/constant.js'
import ShortKey from '@/views/modules/ultils/ShortKey'
import { createPopper } from '@popperjs/core'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'
import { rateSetting } from '@/api/rate-setting'
import { golfClassSetting } from '@/api/golf-class-setting'
import { commonServices } from '@/api/common-services'

const stringMath = require('string-math')

export default {
  name: 'RateCourse',
  components: {
    Cleave,
    SelectCheckbox,
    GolfService,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    ShortKey
  },
  props: {
    listCourse: {
      type: Array,
      required: false,
    },
    listTransaction: {
      type: Array,
      required: false,
    },
    listCourseAdded: {
      type: Array,
      required: false,
    },
    inActive: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      inputMask: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
        time: {
          time: true,
          timePattern: ['h', 'm'],
        },
      },
      isOpen: false,
      rateId: null,
      RateParentId: null,
      rateCourseId: null,
      listNumberOfHoles: [
        {
          value: 9,
          text: `9 ${this.$t('golf_common_hole')}`,
        },
        {
          value: 18,
          text: `18 ${this.$t('golf_common_hole')}`,
        },
        {
          value: 27,
          text: `27 ${this.$t('golf_common_hole')}`,
        },
        {
          value: 36,
          text: `36 ${this.$t('golf_common_hole')}`,
        },
        {
          value: 45,
          text: `45 ${this.$t('golf_common_hole')}`,
        },
        {
          value: 54,
          text: `54 ${this.$t('golf_common_hole')}`,
        },
      ],
      listCourseNotAvail: [],
      detailRateCourse: {
        Rate: {
          Id: this.rateId,
          RateCourse: {
            CourseId: null,
            RateCourseInfo: [
              {
                GolfClassId: null,
                RateCourseInfoDetail: [
                  {
                    NumberOfHole: [9],
                    ServiceList: [
                      {
                        ServiceId: null,
                        Price: null,
                        Formula: '*1',
                        FinalPrice: null,
                        StartTime: '00:00',
                        EndTime: '23:59',
                        IsDefault: true,
                        TransactionCode: null,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
      },
      listGolfClass: [],
      servicePosition: [],
      currentHoverPosition: [],
      copyGolfClassPosition: null,
      copyHolePosition: [],
      isLoading: false,
      windowWidth: window.innerWidth,
      popupWidth: 760,
      ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, S, R, P],
      allChildComponentClosed: [],
      isViewHistory: false,
      checkModuleEInvocie: JSON.parse(localStorage.getItem('Einvoice')),
      isEditTransaction: null
    }
  },
  watch: {
    windowWidth(value) {
      if (value > 1024) {
        this.popupWidth = (value * 75) / 100
      }
    },
    isOpen(value) {
      if(!value) {
        this.isViewHistory = false
      }
      this.$emit('is-open', { ComponentId: 'add-new-rate-course-detail', IsActive: value })
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  created() {
    this.getConfig_RCOM01()
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    async open(rateId, rateParentId, rateCourseId) {
      this.isOpen = true
      this.rateCourseId = rateCourseId
      this.RateParentId = rateParentId
      this.rateId = rateId
      this.currentHoverPosition = []

      if (this.rateCourseId) {
        // Lấy chi tiết rate course
        await this.init_RRA01_AC1()
      } else {
        this.detailRateCourse = {
          Rate: {
            Id: this.rateId,
            RateCourse: {
              CourseId: null,
              RateCourseInfo: [
                {
                  GolfClassId: null,
                  RateCourseInfoDetail: [
                    {
                      NumberOfHole: [9],
                      ServiceList: [
                        {
                          ServiceId: null,
                          Price: null,
                          Formula: '*1',
                          FinalPrice: null,
                          StartTime: '00:00',
                          EndTime: '23:59',
                          IsDefault: true,
                          TransactionCode: null,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
        }
        // Nếu sân ko bị ngừng hoạt động và chưa có cấu hình giá cho sân đó thì mặc định giá trị đầu tiên
        if (this.listCourse.filter(x => !x.InActive && !this.listCourseAdded.includes(x.Id)).length) {
          this.detailRateCourse.Rate.RateCourse.CourseId = this.listCourse.filter(x => !x.InActive && !this.listCourseAdded.includes(x.Id))[0].Id
          this.getListGolfClass(this.detailRateCourse.Rate.RateCourse.CourseId)
        }
      }
    },
    openHistory(item, parentRateId) {
      this.RateParentId = parentRateId
      this.isOpen = true
      this.isViewHistory = true
      this.detailRateCourse = JSON.parse(item.NewValue)
    },
    async getConfig_RCOM01() {
      const body = {
        KeyGroup: 'RATE_CONFIG',
      }
      await commonServices
        .getCommon(body)
        .then(res => {
          this.isEditTransaction = res?.Data.find(x => x.KeyCode === 'RATE_ALLOW_EDIT_TRANSACTION_CODE').KeyValue
        })
    },
    hideModal() {
      this.isOpen = false
    },
    afterUpdateRateCourse(res) {
      this.$emit('afterUpdateRateCourse', res)
    },
    async init_RRA01_AC1() {
      this.isLoading = true
      // Xem chi tiết của rate course
      const body = {
        RateCourse: {
          Id: this.rateCourseId,
        },
      }
      await rateSetting
        .getDetailRateCourse(body)
        .then(res => {
          if (res.Status === '200') {
            this.detailRateCourse = {
              Rate: {
                Id: res.Data.RateCourse.RateId,
                RateCourse: {
                  Id: res.Data.RateCourse.Id,
                  CourseId: res.Data.RateCourse.CourseId,
                  RateCourseInfo: res.Data.RateCourse.RateCourseInfo,
                },
              },
            }

            // Nếu là rate con thì chuyển hết golf class sang object
            if (this.RateParentId) {
              this.detailRateCourse.Rate.RateCourse.RateCourseInfo.forEach(x => {
                x.GolfClassId = x.GolfClassId[0]
                // x.RateCourseInfoDetail.forEach(y => {
                // y.NumberOfHole = y.NumberOfHole[0]
                // })
              })
            }

            // Lấy danh sách golf class theo course Id
            this.getListGolfClass(res.Data.RateCourse.CourseId)
          } else {
            this.showResToast(res)
          }
        })
      this.isLoading = false
    },
    addNewRateCourse_IRA01_AC5() {
      this.$refs.rateCourseForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          this.detailRateCourse.Rate.Id = this.rateId
          const body = JSON.parse(JSON.stringify(this.detailRateCourse)) // this.detailRateCourse

          // Nếu là rate con thì chuyển hole, golf class chuyển sang mảng vì mặc định vue-select chuyển select sang object
          if (this.RateParentId) {
            body.Rate.RateCourse.RateCourseInfo.forEach(x => {
              x.GolfClassId = [x.GolfClassId]
              x.RateCourseInfoDetail.forEach(y => {
                // y.NumberOfHole = [y.NumberOfHole]
                y.ServiceList.forEach(z => {
                  z.Price = parseFloat(z.Price)
                })
              })
            })
          }
          rateSetting
            .andNewRateCourse(body)
            .then(res => {
              this.showResToast(res)
              if (res.Status === '200') {
                // Đóng popup thêm rate
                this.isOpen = false
                // Load lại thông tin detail giá
                this.afterUpdateRateCourse()
              }
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    updateRateCourse_URA01_AC8() {
      this.$refs.rateCourseForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          const body = JSON.parse(JSON.stringify(this.detailRateCourse))
          if (this.RateParentId) {
            body.Rate.RateCourse.RateCourseInfo.forEach(x => {
              x.GolfClassId = [x.GolfClassId]
              x.RateCourseInfoDetail.forEach(y => {
                // y.NumberOfHole = [y.NumberOfHole]
                y.ServiceList.forEach(z => {
                  z.Price = parseFloat(z.Price)
                })
              })
            })
          }
          rateSetting
            .updateRateCourse(body)
            .then(res => {
              this.showResToast(res)
              if (res.Status === '200') {
                // Đóng popup thêm rate
                this.isOpen = false
                // Load lại thông tin detail giá
                this.afterUpdateRateCourse()
              }
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    addRateCourseInfo() {
      // Thêm khối golf class
      this.detailRateCourse.Rate.RateCourse.RateCourseInfo.push(
        {
          GolfClassId: null,
          RateCourseInfoDetail: [
            {
              NumberOfHole: [9],
              ServiceList: [
                {
                  ServiceId: null,
                  Price: null,
                  Formula: '*1',
                  FinalPrice: null,
                  StartTime: '00:00',
                  EndTime: '23:59',
                  IsDefault: true,
                  TransactionCode: null,
                  ServiceId4eInvoice: null
                },
              ],
            },
          ],
        },
      )
    },
    // copyRateCourseInfo(index) {
    //   this.detailRateCourse.Rate.RateCourse.RateCourseInfo.push(
    //     JSON.parse(JSON.stringify(this.detailRateCourse.Rate.RateCourse.RateCourseInfo[index])),
    //   )
    // },
    deleteRateCourseInfo(index) {
      // Xoá khối golf class
      this.detailRateCourse.Rate.RateCourse.RateCourseInfo.splice(index, 1)
    },
    addRateCourseInfoDetail(index) {
      // Thêm khối dịch vụ và hole
      this.detailRateCourse.Rate.RateCourse.RateCourseInfo[index].RateCourseInfoDetail.push(
        {
          NumberOfHole: [9],
          ServiceList: [
            {
              ServiceId: null,
              Price: null,
              Formula: '*1',
              FinalPrice: null,
              StartTime: '00:00',
              EndTime: '23:59',
              IsDefault: true,
              TransactionCode: null,
              ServiceId4eInvoice: null
            },
          ],
        },
      )
    },
    copyRateCourseInfoDetail(i, j) {
      this.detailRateCourse.Rate.RateCourse.RateCourseInfo[i].RateCourseInfoDetail.push(
        JSON.parse(JSON.stringify(this.detailRateCourse.Rate.RateCourse.RateCourseInfo[i].RateCourseInfoDetail[j])),
      )
    },
    deleteRateCourseInfoDetail(i, j) {
      // Xoá khối dịch vụ và hole
      this.detailRateCourse.Rate.RateCourse.RateCourseInfo[i].RateCourseInfoDetail.splice(j, 1)
    },
    deleteServiceList(i, j, k) {
      // Xoá item service
      this.detailRateCourse.Rate.RateCourse.RateCourseInfo[i].RateCourseInfoDetail[j].ServiceList.splice(k, 1)
    },
    addRateCourseInfoDetailServiceList(i, j) {
      // Thêm item dịch vụ
      this.detailRateCourse.Rate.RateCourse.RateCourseInfo[i].RateCourseInfoDetail[j].ServiceList.push(
        {
          ServiceId: null,
          Price: null,
          Formula: '*1',
          FinalPrice: null,
          StartTime: '00:00',
          EndTime: '23:59',
          IsDefault: true,
          TransactionCode: null,
          ServiceId4eInvoice: null
        },
      )
    },
    addRateCalc(i, j) {
      let price = 0
      this.detailRateCourse.Rate.RateCourse.RateCourseInfo[i].RateCourseInfoDetail[j].ServiceList.forEach(x => {
        if (x.FinalPrice > 0) {
          price += parseInt(x.FinalPrice)
        }
      })
      return price
    },
    updateFinalPrice(i, j, k, isRateChild) {
      if (!isRateChild) {
        // Chỉ cần cập nhật tổng giá
        this.detailRateCourse.Rate.RateCourse.RateCourseInfo[i].RateCourseInfoDetail[j].ServiceList[k].Price = parseFloat(this.detailRateCourse.Rate.RateCourse.RateCourseInfo[i].RateCourseInfoDetail[j].ServiceList[k].Price)
        this.detailRateCourse.Rate.RateCourse.RateCourseInfo[i].RateCourseInfoDetail[j].ServiceList[k].FinalPrice = parseFloat(this.detailRateCourse.Rate.RateCourse.RateCourseInfo[i].RateCourseInfoDetail[j].ServiceList[k].Price)
      } else {
        if(Number(this.detailRateCourse.Rate.RateCourse.RateCourseInfo[i].RateCourseInfoDetail[j].ServiceList[k].Formula.trim().substr(0, 1))) {
          this.detailRateCourse.Rate.RateCourse.RateCourseInfo[i].RateCourseInfoDetail[j].ServiceList[k].FinalPrice = Number(this.detailRateCourse.Rate.RateCourse.RateCourseInfo[i].RateCourseInfoDetail[j].ServiceList[k].Formula)
        } else {
          this.detailRateCourse.Rate.RateCourse.RateCourseInfo[i].RateCourseInfoDetail[j].ServiceList[k].FinalPrice = stringMath(this.detailRateCourse.Rate.RateCourse.RateCourseInfo[i].RateCourseInfoDetail[j].ServiceList[k].Price + this.detailRateCourse.Rate.RateCourse.RateCourseInfo[i].RateCourseInfoDetail[j].ServiceList[k].Formula)
        }
        // Cập nhật giá của dịch vụ và tổng giá
      }
    },
    removeServie(i, j, k) {
      this.detailRateCourse.Rate.RateCourse.RateCourseInfo[i].RateCourseInfoDetail[j].ServiceList.splice(k, 1)
    },
    async getListGolfClass(courseId) {
      // Lấy danh sách golf class
      const body = {
        KeyWord: null,
        CourseId: [courseId],
      }
      await golfClassSetting
        .getListGolfClass(body)
        .then(res => {
          if (res.Status === '200') {
            this.listGolfClass = res.Data.GolfClass
          } else {
            this.showResToast(res)
          }
        })
    },
    async deleteRateCourse(RateCourseId) {
      // Xoá giá sân trong rate
      const body = {
        RateCourse: {
          Id: RateCourseId,
        },
      }
      await rateSetting
        .deleteRateCourse(body)
        .then(res => {
          this.showResToast(res)
          if (res.Status === '200') {
            this.init_RRA01_AC1()
          }
        })
    },
    setPosition(i, j, k) {
      this.servicePosition = [i, j, k]
    },
    selectService(service) {
      // set mặc định service hiển thị einvoice giống service chọn
      if(!this.detailRateCourse.Rate.RateCourse.RateCourseInfo[this.servicePosition[0]].RateCourseInfoDetail[this.servicePosition[1]].ServiceList[this.servicePosition[2]].ServiceId4eInvoice) {
        this.detailRateCourse.Rate.RateCourse.RateCourseInfo[this.servicePosition[0]].RateCourseInfoDetail[this.servicePosition[1]].ServiceList[this.servicePosition[2]].ServiceId4eInvoice = service.ProductId
      }
      // Set giá mặc định khi chọn dịch vụ
      this.detailRateCourse.Rate.RateCourse.RateCourseInfo[this.servicePosition[0]].RateCourseInfoDetail[this.servicePosition[1]].ServiceList[this.servicePosition[2]].Price = service.ProductPrice

      // Set transaction code mặc định khi chọn dịch vụ
      this.detailRateCourse.Rate.RateCourse.RateCourseInfo[this.servicePosition[0]].RateCourseInfoDetail[this.servicePosition[1]].ServiceList[this.servicePosition[2]].TransactionCode = service.TransactionCode.TransactionCode
    },
    copyGolfClasss(i) {
      this.copyGolfClassPosition = i
    },
    pasteGolfClass(i) {
      this.detailRateCourse.Rate.RateCourse.RateCourseInfo.splice(i, 1, JSON.parse(JSON.stringify(this.detailRateCourse.Rate.RateCourse.RateCourseInfo[this.copyGolfClassPosition])))
      // this.copyGolfClassPosition = null
    },
    copyHole(i, j) {
      this.copyHolePosition = [i, j]
    },
    pasteHole(i, j) {
      this.detailRateCourse.Rate.RateCourse.RateCourseInfo[i].RateCourseInfoDetail.splice(j, 1, JSON.parse(JSON.stringify(this.detailRateCourse.Rate.RateCourse.RateCourseInfo[this.copyHolePosition[0]].RateCourseInfoDetail[this.copyHolePosition[1]])))
    },
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = '400px'
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 0],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              )
            },
          },
        ],
      })
      return () => popper.destroy()
    },
    onTriggeredEventHandler(payload) {
      if (payload.evt.ctrlKey && payload.key === S) {
        if(!this.detailRateCourse.Rate.RateCourse.Id) {
          this.addNewRateCourse_IRA01_AC5()
        } else {
          this.updateRateCourse_URA01_AC8()
        }
      } 
    },
  },
}
